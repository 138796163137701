<template>
	<div class="dashboard">
		<AppTopbar
		title="Dashboard"
		buttonText="New Order"
		buttonLink="new"
		buttonIconClass="icon-add"
		></AppTopbar>

		<div class="container-fluid pt-70 pt-md-100 pb-40 pb-md-0">
			<div class="card-wrapper pb-20 pb-md-0">
				<Card iconClass="icon-total_deliveries" iconColor="orange">
					<template v-slot:header>
						<span class="text-gray-600 lh24">
							Total Shipments
							<i
							class="icon-disclaimer fs12 text-gray-400 ml-12 vam"
							v-b-tooltip.hover.right
							title="This Month Shipments"
							></i>
						</span>
						<!-- <span class="text-gray-400 lh24">
							<i class="icon-dropup text-green vam fs8 mr-10"></i>
							20%
						</span> -->
					</template>
					<slot>
						<span v-if="overview.due!==undefined" class="card-sm__text">
							{{ totalShipments() }}
						</span>
						<span v-else class="card-sm__text d-inline-block w-50">
							<b-skeleton animation="wave"></b-skeleton>
						</span>
					</slot>
				</Card>

				<Card iconClass="icon-due_amount" iconColor="blue">
					<template v-slot:header>
						<span class="text-gray-600 lh24">
							{{user.prepaid == 0 ? 'Due' : 'Usable'}} Amount
							<i
							class="icon-disclaimer fs12 text-gray-400 ml-12 vam"
							v-b-tooltip.hover.right
							:title="user.prepaid == 0 ? 'Total Due' : 'Available Balance'"
							></i>
						</span>
						<!-- <span class="text-gray-400 lh24">
							<i class="icon-dropup text-green vam fs8 mr-10"></i>
							20%
						</span>-->
					</template>
					<slot>
						<span v-if="overview.due!==undefined" class="card-sm__text">
							{{ overview.due | currency('inr') }}
						</span>
						<span v-else class="card-sm__text d-inline-block w-50">
							<b-skeleton animation="wave"></b-skeleton>
						</span>
					</slot>
				</Card>

				<Card iconClass="icon-expected_cod" iconColor="green">
					<template v-slot:header>
						<span class="text-gray-600 lh24">
							Expected COD
							<i
							class="icon-disclaimer fs12 text-gray-400 ml-12 vam"
							v-b-tooltip.hover.right
							title="Expected COD"
							></i>
						</span>
						<!-- <span class="text-gray-400 lh24">
							<i class="icon-dropup text-green vam fs8 mr-10"></i>
							20%
						</span> -->
					</template>
					<slot>
						<span v-if="overview.expectedCod!==undefined" class="card-sm__text">
							{{ overview.expectedCod | currency('inr') }}
						</span>
						<span v-else class="card-sm__text d-inline-block w-50">
							<b-skeleton animation="wave"></b-skeleton>
						</span>
					</slot>
				</Card>

				<Card iconClass="icon-pending_orders" iconColor="orange">
					<template v-slot:header>
						<span class="text-gray-600 lh24">
							Unprocessed orders
							<i
							class="icon-disclaimer fs12 text-gray-400 ml-12 vam"
							v-b-tooltip.hover.right

							title="This Month Pending Orders"
							></i>
						</span>
						<!-- <span class="text-gray-400 lh24">
							<i class="icon-dropdown-triangle text-orange vam fs8 mr-10"></i>
							5%
						</span> -->
					</template>
					<slot>
						<span v-if="overview.pendingCount!==undefined" class="card-sm__text">
							{{ overview.pendingCount ? overview.pendingCount : 0 }}
						</span>
						<span v-else class="card-sm__text d-inline-block w-50">
							<b-skeleton animation="wave"></b-skeleton>
						</span>
					</slot>
				</Card>
			</div>

			<div class="row pb-40 pb-md-0">
				<div class="col-lg-9 pb-20">
					<div class="card card-movement">
						<div class="card__title d-block">
							<span class="fs16 lh20 text-gray-800 fw500">Analytics</span>
							<div class="float-right text-gray-600">This Month Report</div>
						</div>
						<div class="card__content">
							<div class="row">
								<div class="col-md-6">
									<ColumnChart :data="orderTypeChart" />
								</div>
								<div class="col-md-6">
									<DonutChart :data="modeDonutChart" />
								</div>
							</div>
						</div>
						<div class="card__footer bg-light-blue px-8">
							<div class="row align-items-center pl-20 pr-25">
								<div class="d-flex">
									<i
									class="icon-analytics-variant my-auto fs24 text-blue mr-20"
									></i>
									<div class="d-md-flex align-items-center">
										<span class="d-block fs16 lh20 fw500 text-gray-800">
											Analytics
										</span>
										<span class="fs14 lh24 fw400 ml-0 ml-md-16 text-gray-800">
											Shyplite provides you insightful data
										</span>
									</div>
								</div>
								<b-button to="/analytics" variant="secondary" size="sm" class="ml-auto w-sm-100 mt-20 mt-md-0">
									Check Analytics <i class="icon-arrow fs10 ml-7"></i>
								</b-button>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-3 pb-20">
					<div class="card card-movement gradient">
						<div class="card__title">
							<span class="d-block fs16 lh20 fw500 text-gray-800">Recent Pending Orders</span>
						</div>

						<div v-if="!overview.pending" class="card__content" style="min-height:420px">
							<b-skeleton animation="wave" class="mb-10"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="75%"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="40%"></b-skeleton>
							<div class="mb-30"></div>
							<b-skeleton animation="wave" class="mb-10"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="75%"></b-skeleton>
							<b-skeleton animation="wave" class="mb-10" width="20%"></b-skeleton>
						</div>

						<div v-else-if="overview.pending.length>0" class="card__content">
							<ul class="movement-timeline">
								<li v-for="ticketUpdate in overview.pending" :key="ticketUpdate.id" class="cp" @click="$store.commit('setTab', 0);$router.push('/shipments')">
									<div class="d-flex align-items-start justify-content-between">
										<span class="status">
											{{ ticketUpdate.importedID }}
										</span>
										<span class="meta wsnw mt-2">
											{{ ticketUpdate.pincode }}
										</span>
									</div>

									<span class="meta my-4">
										{{ ticketUpdate.type | uppercase }}
									</span>

									<div class="d-flex align-items-center justify-content-between">
										<template>
											<span class="text-gray-600">
												₹{{ ticketUpdate.totalValue.toFixed(2) }}
											</span>
										</template>
									</div>
								</li>
							</ul>
							<div class="gradient"></div>
						</div>
						<span v-else class="d-block p-20 text-gray-400" style="min-height:420px">No latest pending orders found!</span>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
(function(h,e,a,t,m,p) {
	m=e.createElement(a);m.async=!0;m.src=t;
	p=e.getElementsByTagName(a)[0];p.parentNode.insertBefore(m,p);
})(window,document,'script','https://u.heatmap.it/log.js');

import DonutChart from "@/components/charts/DonutChart.vue";
import ColumnChart from "@/components/charts/ColumnChart.vue";
import { mapState } from "vuex";
import Card from "@/components/dashboardCard.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";

export default {
	name: "Dashboard",
	title: "Shyplite - Dashboard",
	components: {
		Card,
		AppTopbar,
		DonutChart,
		ColumnChart,
	},
	data() {
		return {
			modeDonutChart: {
				id: "modeDonut",
				title: "Mode Type",
				height: {
					minHeight: "240px",
					maxHeight: "240px"
				}
			},
			orderTypeChart: {
				id: "OrderType",
				title: "Order Type",
				height: {minHeight: "240px", maxHeight: "240px"},
			},
			overview: this.$store.getters.global.overview || {},
			updatedDue : this.$store.state.due || null
		}
	},
	created() {
		// const now = Math.floor(Date.now()/1000)
		// if(!this.$store.getters.user.overview_timestamp || this.$store.getters.user.overview_timestamp < now) {
		// 	this.getOverview()
		// 	this.$store.commit('setUser', {overview_timestamp: now + 3*3600})
		// }
		this.getOverview()
		this.ordersType()
		this.modesDonut()
		this.totalShipments()
	},
	methods: {
		navigate() {
			this.$router.push("/support");
		},
		detectHeight() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty("--vh", `${vh}px`);
		},
		modesDonut() {
			let mtypes = {1:0, 2:0, 10:0, 8:0, 3:0, 9:0}
			for(let item in this.data) {
				for(let m in mtypes) {
					if(this.data[item].mode) {
						mtypes[m] += this.data[item].mode[m]?this.data[item].mode[m].total:0
					}
				}
			}
			let total = 0
			for(let m in mtypes) {
				total += mtypes[m]
			}
			this.modeDonutChart.total = total
			this.modeDonutChart.chartData = [
			{
				name: "Air",
				y: mtypes[1],
				color: "#64d778",
			},
			{
				name: "Surface-10kg",
				y: mtypes[2],
				color: "#fa5a5a",
			},
			{
				name: "Surface-5kg",
				y: mtypes[10],
				color: "#a064e6",
			},
			{
				name: "Lite-1kg",
				y: mtypes[8],
				color: "#ffcd3c",
			},
			{
				name: "Lite-2kg",
				y: mtypes[3],
				color: "#006eff",
			},
			{
				name: "Lite-0.5kg",
				y: mtypes[9],
				color: "#baf7ba",
			}
			]
		},
		ordersType() {
			let prepaid = [], cod = [], reverse = [], categories = []
			let i = 0
			for(let item in this.data) {
				categories[i] = new Date(parseInt(item)).toLocaleDateString('en-IN', { month: '2-digit', day: '2-digit' })
				prepaid[i] = this.data[item].type && this.data[item].type.prepaid ? this.data[item].type.prepaid.total:null
				cod[i] = this.data[item].type && this.data[item].type.cod ? this.data[item].type.cod.total : null
				reverse[i] = this.data[item].type && this.data[item].type.reverse ? this.data[item].type.reverse.total : null
				i++
			}
			this.orderTypeChart.categories = categories
			this.orderTypeChart.chartData = [
			{ name: "Prepaid", data: prepaid, color: "#006eff"},
			{ name: "COD", data: cod, color: "#64d778"},
			{ name: "Reverse", data: reverse, color: "#fa5a5a"},
			]
		},

		async getOverview() {
			const res = await this.axios.get('/v3/dashboard/overview')
			this.overview = res.data
			this.$store.commit('setGlobal', {overview: res.data})
		},

		totalShipments() {
			let cod = 0, prepaid = 0, reverse = 0

			for(let item in this.data) {
				prepaid += this.data[item].type && this.data[item].type.prepaid ? this.data[item].type.prepaid.total : 0
				cod += this.data[item].type && this.data[item].type.cod ? this.data[item].type.cod.total : 0
				reverse += this.data[item].type && this.data[item].type.reverse ? this.data[item].type.reverse.total : 0
			}
			return cod +  prepaid + reverse
		}
	},
	mounted() {
		this.detectHeight();
	},
	computed: {
		ticketUpdates() {
			return this.$store.getters.ticketUpdates;
		},
		data() {
			return this.$store.getters.chartdata
		},
		...mapState(["user"])
	}
};
</script>

<style scoped>
.card-sm__text{
	font-size:1.5rem;
}
</style>
