<template>
  <highcharts :data="data" :options="chartOptions" :updateArgs="updateArgs"></highcharts>
</template>

<script>
export default {
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      chartOptions: {
        chart: {
          minHeight: this.data.height.minHeight,
          maxHeight: this.data.height.maxHeight,
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          style: {
            fontFamily: '"Roboto", sans-serif'
          }
        },
        title: {
          text: this.data.title,
          align: "left",
          style: {
            fontSize: "20px",
            lineHeight: "20px",
            color: "#2d374b",
            fontWeight: "500",
          },
        },
        tooltip: {
          formatter: function() {
            return (
              '<span style="font-size:16px; font-weight:500; color:#fff;">'+ this.key + ': ' + this.y + ' <small>(' +
              Math.round(this.y * 100 / this.total) +
              "%)</small></span>"
            );
          },
          borderColor: "#2d374b",
          backgroundColor: "#2d374b",
          borderRadius: 10,
          padding: 10,
          shared: true,
          useHTML: true,
          style: {
            color: "#fff",
            fontWeight: "normal",
          },
        },
        legend: {
          align: "center",
          verticalAlign: "top",
          layout: "horizontal",
          symbolHeight: 12,
          symbolWidth: 12,
          itemMarginTop: 12,
          itemMarginBottom: 0,
          symbolPadding: 8,
          padding: 10,
          x: 10,
          y: -10,
          width: "100%",
          itemStyle: {
            fontSize: "14px",
            lineHeight: "24px",
            color: "#545f75",
            fontWeight: "normal",
          },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            innerSize: "66%",
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            data: this.data.chartData
          },
        ],
      },
    };
  },
  props: {
    data: Object
  }
};
</script>
