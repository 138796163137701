var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Dashboard",
          buttonText: "New Order",
          buttonLink: "new",
          buttonIconClass: "icon-add"
        }
      }),
      _c(
        "div",
        { staticClass: "container-fluid pt-70 pt-md-100 pb-40 pb-md-0" },
        [
          _c(
            "div",
            { staticClass: "card-wrapper pb-20 pb-md-0" },
            [
              _c(
                "Card",
                {
                  attrs: {
                    iconClass: "icon-total_deliveries",
                    iconColor: "orange"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "text-gray-600 lh24" }, [
                            _vm._v(" Total Shipments "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "icon-disclaimer fs12 text-gray-400 ml-12 vam",
                              attrs: { title: "This Month Shipments" }
                            })
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._t("default", [
                    _vm.overview.due !== undefined
                      ? _c("span", { staticClass: "card-sm__text" }, [
                          _vm._v(" " + _vm._s(_vm.totalShipments()) + " ")
                        ])
                      : _c(
                          "span",
                          { staticClass: "card-sm__text d-inline-block w-50" },
                          [_c("b-skeleton", { attrs: { animation: "wave" } })],
                          1
                        )
                  ])
                ],
                2
              ),
              _c(
                "Card",
                {
                  attrs: { iconClass: "icon-due_amount", iconColor: "blue" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "text-gray-600 lh24" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.user.prepaid == 0 ? "Due" : "Usable"
                                ) +
                                " Amount "
                            ),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "icon-disclaimer fs12 text-gray-400 ml-12 vam",
                              attrs: {
                                title:
                                  _vm.user.prepaid == 0
                                    ? "Total Due"
                                    : "Available Balance"
                              }
                            })
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._t("default", [
                    _vm.overview.due !== undefined
                      ? _c("span", { staticClass: "card-sm__text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(_vm.overview.due, "inr")
                              ) +
                              " "
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "card-sm__text d-inline-block w-50" },
                          [_c("b-skeleton", { attrs: { animation: "wave" } })],
                          1
                        )
                  ])
                ],
                2
              ),
              _c(
                "Card",
                {
                  attrs: { iconClass: "icon-expected_cod", iconColor: "green" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "text-gray-600 lh24" }, [
                            _vm._v(" Expected COD "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "icon-disclaimer fs12 text-gray-400 ml-12 vam",
                              attrs: { title: "Expected COD" }
                            })
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._t("default", [
                    _vm.overview.expectedCod !== undefined
                      ? _c("span", { staticClass: "card-sm__text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.overview.expectedCod,
                                  "inr"
                                )
                              ) +
                              " "
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "card-sm__text d-inline-block w-50" },
                          [_c("b-skeleton", { attrs: { animation: "wave" } })],
                          1
                        )
                  ])
                ],
                2
              ),
              _c(
                "Card",
                {
                  attrs: {
                    iconClass: "icon-pending_orders",
                    iconColor: "orange"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "text-gray-600 lh24" }, [
                            _vm._v(" Unprocessed orders "),
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.right",
                                  modifiers: { hover: true, right: true }
                                }
                              ],
                              staticClass:
                                "icon-disclaimer fs12 text-gray-400 ml-12 vam",
                              attrs: { title: "This Month Pending Orders" }
                            })
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._t("default", [
                    _vm.overview.pendingCount !== undefined
                      ? _c("span", { staticClass: "card-sm__text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.overview.pendingCount
                                  ? _vm.overview.pendingCount
                                  : 0
                              ) +
                              " "
                          )
                        ])
                      : _c(
                          "span",
                          { staticClass: "card-sm__text d-inline-block w-50" },
                          [_c("b-skeleton", { attrs: { animation: "wave" } })],
                          1
                        )
                  ])
                ],
                2
              )
            ],
            1
          ),
          _c("div", { staticClass: "row pb-40 pb-md-0" }, [
            _c("div", { staticClass: "col-lg-9 pb-20" }, [
              _c("div", { staticClass: "card card-movement" }, [
                _vm._m(0),
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("ColumnChart", {
                          attrs: { data: _vm.orderTypeChart }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c("DonutChart", {
                          attrs: { data: _vm.modeDonutChart }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "card__footer bg-light-blue px-8" }, [
                  _c(
                    "div",
                    { staticClass: "row align-items-center pl-20 pr-25" },
                    [
                      _vm._m(1),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-auto w-sm-100 mt-20 mt-md-0",
                          attrs: {
                            to: "/analytics",
                            variant: "secondary",
                            size: "sm"
                          }
                        },
                        [
                          _vm._v(" Check Analytics "),
                          _c("i", { staticClass: "icon-arrow fs10 ml-7" })
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "col-lg-3 pb-20" }, [
              _c("div", { staticClass: "card card-movement gradient" }, [
                _vm._m(2),
                !_vm.overview.pending
                  ? _c(
                      "div",
                      {
                        staticClass: "card__content",
                        staticStyle: { "min-height": "420px" }
                      },
                      [
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave" }
                        }),
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave", width: "75%" }
                        }),
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave", width: "40%" }
                        }),
                        _c("div", { staticClass: "mb-30" }),
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave" }
                        }),
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave", width: "75%" }
                        }),
                        _c("b-skeleton", {
                          staticClass: "mb-10",
                          attrs: { animation: "wave", width: "20%" }
                        })
                      ],
                      1
                    )
                  : _vm.overview.pending.length > 0
                  ? _c("div", { staticClass: "card__content" }, [
                      _c(
                        "ul",
                        { staticClass: "movement-timeline" },
                        _vm._l(_vm.overview.pending, function(ticketUpdate) {
                          return _c(
                            "li",
                            {
                              key: ticketUpdate.id,
                              staticClass: "cp",
                              on: {
                                click: function($event) {
                                  _vm.$store.commit("setTab", 0)
                                  _vm.$router.push("/shipments")
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-start justify-content-between"
                                },
                                [
                                  _c("span", { staticClass: "status" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(ticketUpdate.importedID) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "meta wsnw mt-2" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(ticketUpdate.pincode) + " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("span", { staticClass: "meta my-4" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("uppercase")(ticketUpdate.type)
                                    ) +
                                    " "
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-between"
                                },
                                [
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "text-gray-600" },
                                      [
                                        _vm._v(
                                          " ₹" +
                                            _vm._s(
                                              ticketUpdate.totalValue.toFixed(2)
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                ],
                                2
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      _c("div", { staticClass: "gradient" })
                    ])
                  : _c(
                      "span",
                      {
                        staticClass: "d-block p-20 text-gray-400",
                        staticStyle: { "min-height": "420px" }
                      },
                      [_vm._v("No latest pending orders found!")]
                    )
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title d-block" }, [
      _c("span", { staticClass: "fs16 lh20 text-gray-800 fw500" }, [
        _vm._v("Analytics")
      ]),
      _c("div", { staticClass: "float-right text-gray-600" }, [
        _vm._v("This Month Report")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c("i", {
        staticClass: "icon-analytics-variant my-auto fs24 text-blue mr-20"
      }),
      _c("div", { staticClass: "d-md-flex align-items-center" }, [
        _c("span", { staticClass: "d-block fs16 lh20 fw500 text-gray-800" }, [
          _vm._v(" Analytics ")
        ]),
        _c(
          "span",
          { staticClass: "fs14 lh24 fw400 ml-0 ml-md-16 text-gray-800" },
          [_vm._v(" Shyplite provides you insightful data ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__title" }, [
      _c("span", { staticClass: "d-block fs16 lh20 fw500 text-gray-800" }, [
        _vm._v("Recent Pending Orders")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }